export const API_METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
  POST: 'POST',
} as const;

export const API = {
  SCORER: {
    GET: '/Scorers/all-connected',
  },
  LIVE_DATA_CLIENTS: {
    GET: '/LiveDataClients',
    PUT: '/LiveDataClients',
  },
  USERS: {
    GET: '/Users',
  },
  SPORTS: {
    GET: '/Sports',
  },
  ANALYTICS: {
    GET: '/v3/Analytics/get-fixture-analytics',
  },
  Schedule: (scheduleId?: string) => {
    const path = '/Simulation/Schedules';
    return {
      simulationSchedules: {
        GET: `${path}/all`,
      },
      deleteSchedule: {
        DELETE: `${path}/${scheduleId}/delete`,
      },
    };
  },
  Fixture: (fixtureId?: string) => {
    const path = 'Fixture';
    return {
      fixtureDiagnostics: {
        GET: `/${path}/get-fixture-action-diagnostics/${fixtureId}`,
      },
      fixtureConfig: {
        GET: `/${path}/${fixtureId}/config`,
      },
      fixtureDetails: {
        GET: `/Fixtures/${fixtureId}`,
      },
      fixtureImport: {
        GET: '/Fixtures/FixtureImport',
      },
    };
  },
  Simulation: (simulationAction?: string, fixtureId?: string) => {
    const path = '/Simulation';
    return {
      GET: `${path}`,
      POST: `${path}/${fixtureId}/${simulationAction}`,
    };
  },
} as const;
