const API_BASE_URI = import.meta.env.VITE_API_BASE_URI;
const FA_BASE_URL = import.meta.env.VITE_FA_API_URL;
const FAB_BASE_URL = import.meta.env.VITE_FA_APIB_URL;
const FP_BASE_URL = import.meta.env.VITE_FA_FP_URL;
const MT_BASE_URL = import.meta.env.VITE_MT_URL;
const FP_MT_BASE_URL = import.meta.env.VITE_MT_API_URL;
const IT_BASE_URL = import.meta.env.VITE_IT_URL;

export const URLS = {
  API_BASE_URI,
  FA_BASE_URL,
  FAB_BASE_URL,
  FP_BASE_URL,
  MT_BASE_URL,
  FP_MT_BASE_URL,
  IT_BASE_URL,
};
